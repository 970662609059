var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('v-card',{staticClass:"mb-0 pb-1 mt-n4 parametros-v-card"},[_c('v-row',{staticClass:"mx-auto mt-0",attrs:{"justify":"start"}},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-select',{staticClass:"ml-8",attrs:{"items":_vm.regionaisList,"value":_vm.regionalSelecionada,"label":"Regional selecionado"},on:{"change":_vm.setRegionalSelecionada}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-autocomplete',{staticClass:"ml-5",attrs:{"items":_vm.listaMunicipios,"label":"Município selecionado","clearable":""},model:{value:(_vm.municipioSelecionado),callback:function ($$v) {_vm.municipioSelecionado=$$v},expression:"municipioSelecionado"}})],1)],1)],1),_c('v-row',{attrs:{"dense":"","justify":"center"}},[_c('v-col',{staticClass:"flex-md-grow-1",attrs:{"cols":"12","md":"8"}},[_c('v-card',{staticClass:"my-1"},[_c('v-card-title',[_c('h4',[_vm._v("Mapa Coroplético")])]),_c('v-card-text',[_c('mapa-coropletico-municipios',{attrs:{"labelKpis":{
              compensacao: 'Compensação',
              qtd_protocolos: 'Quantidade de Protocolos',
              qtd_dias_submetidos_nt_inadequado:
                'Quant. UCs com Tensão Não Conforme',
            },"dadosPorMunicipio":_vm.dadosMapaMunicipiosByUcs}})],1)],1)],1)],1),_c('v-row',{attrs:{"dense":"","justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-switch',{staticClass:"switch-tipo-visao",attrs:{"color":"primary","dense":"","hide-details":"","false-value":'municipios',"true-value":'ucs'},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('label',{staticClass:"v-label",class:_vm.tipoVisao == 'municipios'
                ? 'text--primary'
                : 'text--secondary font-weight-light'},[_vm._v(" Por Municípios ")])]},proxy:true},{key:"append",fn:function(){return [_c('label',{staticClass:"v-label",class:_vm.tipoVisao == 'ucs'
                ? 'text--primary'
                : 'text--secondary font-weight-light'},[_vm._v(" Por UCs ")])]},proxy:true}]),model:{value:(_vm.tipoVisao),callback:function ($$v) {_vm.tipoVisao=$$v},expression:"tipoVisao"}})],1)],1),(_vm.tipoVisao === 'ucs')?_c('v-row',{staticClass:"mt-5",attrs:{"dense":"","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-data-table',{staticClass:"elevation-3 data-table-slim pt-3",attrs:{"loading":_vm.loadingItemsGroupedUcs,"headers":_vm.headersTableGroupedUcs,"items":_vm.itemsGroupedUcs,"dense":"","multi-sort":"","single-select":"","show-select":"","item-key":"uc","options":{
          itemsPerPage: 10,
        },"headerProps":{
          sortByText: 'Ordenar Por',
        },"footer-props":{
          showFirstLastPage: true,
          showCurrentPage: true,
          itemsPerPageOptions: [5, 15, 50, 100, -1],
        },"no-data-text":"Nenhum registro disponível","no-results-text":"Nenhum registro correspondente encontrado","mobile-breakpoint":10},scopedSlots:_vm._u([{key:"header.uc",fn:function(ref){
        var header = ref.header;
return [_c('div',{staticClass:"caption font-weight-bold"},[_vm._v(" Total: "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(_vm.totaisItemsGroupedByUcs.uc))+" ")]),_vm._v(" "+_vm._s(header.text)+" ")]}},{key:"header.municipio",fn:function(ref){
        var header = ref.header;
return [_c('div',{staticClass:"caption font-weight-bold"},[_vm._v(" Total: "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(_vm.totaisItemsGroupedByUcs.municipio))+" ")]),_vm._v(" "+_vm._s(header.text)+" ")]}},{key:"header.compensacao",fn:function(ref){
        var header = ref.header;
return [_c('div',{staticClass:"caption font-weight-bold"},[_vm._v(" Total: "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(_vm.totaisItemsGroupedByUcs.compensacao))+" ")]),_vm._v(" "+_vm._s(header.text)+" ")]}},{key:"header.qtd_protocolos",fn:function(ref){
        var header = ref.header;
return [_c('div',{staticClass:"caption font-weight-bold"},[_vm._v(" Total: "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(_vm.totaisItemsGroupedByUcs.qtd_protocolos))+" ")]),_vm._v(" "+_vm._s(header.text)+" ")]}},{key:"item.compensacao",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item.compensacao))+" ")]}},{key:"item.qtd_dias_submetidos_nt_inadequado",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item.qtd_dias_submetidos_nt_inadequado))+" ")]}}],null,true),model:{value:(_vm.ucSelecionada),callback:function ($$v) {_vm.ucSelecionada=$$v},expression:"ucSelecionada"}})],1),(_vm.ucSelecionada.length)?_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('v-data-table',{staticClass:"elevation-3 data-table-slim pt-3",attrs:{"loading":_vm.loadingItemsByUc,"headers":_vm.headersTableByUc,"items":_vm.itemsByUc,"dense":"","multi-sort":"","options":{
          itemsPerPage: 10,
        },"headerProps":{
          sortByText: 'Ordenar Por',
        },"footer-props":{
          showFirstLastPage: true,
          showCurrentPage: true,
          itemsPerPageOptions: [5, 15, 50, 100, -1],
        },"no-data-text":"Nenhum registro disponível","no-results-text":"Nenhum registro correspondente encontrado","mobile-breakpoint":10},scopedSlots:_vm._u([{key:"item.mes_referencia",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatToMonth")(item.mes_referencia))+" ")]}},{key:"item.drp",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item.drp))+" ")]}},{key:"item.drc",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item.drc))+" ")]}},{key:"item.compensacao",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item.compensacao))+" ")]}},{key:"item.qtd_dias_submetidos_nt_inadequado",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item.qtd_dias_submetidos_nt_inadequado))+" ")]}}],null,true)})],1):_vm._e()],1):_vm._e(),(_vm.tipoVisao === 'municipios')?_c('v-row',{staticClass:"mt-5",attrs:{"dense":"","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-data-table',{staticClass:"elevation-3 data-table-slim pt-3",attrs:{"loading":_vm.loadingItemsGroupedMunicipios,"headers":_vm.headersTableGroupedMunicipios,"items":_vm.itemsGroupedMunicipios,"dense":"","multi-sort":"","single-select":"","show-select":"","item-key":"codigo_municipio","options":{
          itemsPerPage: 10,
        },"headerProps":{
          sortByText: 'Ordenar Por',
        },"footer-props":{
          showFirstLastPage: true,
          showCurrentPage: true,
          itemsPerPageOptions: [5, 15, 50, 100, -1],
        },"no-data-text":"Nenhum registro disponível","no-results-text":"Nenhum registro correspondente encontrado","mobile-breakpoint":10},scopedSlots:_vm._u([{key:"header.municipio",fn:function(ref){
        var header = ref.header;
return [_c('div',{staticClass:"caption font-weight-bold"},[_vm._v(" Total: "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(_vm.totaisItemsGroupedByMunicipios.municipio))+" ")]),_vm._v(" "+_vm._s(header.text)+" ")]}},{key:"header.compensacao",fn:function(ref){
        var header = ref.header;
return [_c('div',{staticClass:"caption font-weight-bold"},[_vm._v(" Total: "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(_vm.totaisItemsGroupedByMunicipios.compensacao))+" ")]),_vm._v(" "+_vm._s(header.text)+" ")]}},{key:"header.qtd_ucs",fn:function(ref){
        var header = ref.header;
return [_c('div',{staticClass:"caption font-weight-bold"},[_vm._v(" Total: "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(_vm.totaisItemsGroupedByMunicipios.qtd_ucs))+" ")]),_vm._v(" "+_vm._s(header.text)+" ")]}},{key:"item.compensacao",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item.compensacao))+" ")]}},{key:"item.qtd_dias_submetidos_nt_inadequado",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item.qtd_dias_submetidos_nt_inadequado))+" ")]}}],null,true),model:{value:(_vm.municipioSelecionadoDt),callback:function ($$v) {_vm.municipioSelecionadoDt=$$v},expression:"municipioSelecionadoDt"}})],1),(_vm.municipioSelecionadoDt.length)?_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('v-data-table',{staticClass:"elevation-3 data-table-slim pt-3",attrs:{"loading":_vm.loadingItemsByMunicipio,"headers":_vm.headersTableByMunicipio,"items":_vm.itemsByMunicipio,"dense":"","multi-sort":"","options":{
          itemsPerPage: 10,
        },"headerProps":{
          sortByText: 'Ordenar Por',
        },"footer-props":{
          showFirstLastPage: true,
          showCurrentPage: true,
          itemsPerPageOptions: [5, 15, 50, 100, -1],
        },"no-data-text":"Nenhum registro disponível","no-results-text":"Nenhum registro correspondente encontrado","mobile-breakpoint":10},scopedSlots:_vm._u([{key:"item.mes_referencia",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatToMonth")(item.mes_referencia))+" ")]}},{key:"item.drp",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item.drp))+" ")]}},{key:"item.drc",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item.drc))+" ")]}},{key:"item.compensacao",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item.compensacao))+" ")]}},{key:"item.qtd_dias_submetidos_nt_inadequado",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item.qtd_dias_submetidos_nt_inadequado))+" ")]}}],null,true)})],1):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }